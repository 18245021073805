const sku = [
  {
    zh: "场次",
    tw: "場次",
    us: "session",
    jp: "回です",
    kr: "경기",
  },
  {
    zh: "规格",
    tw: "規格",
    us: "specification",
    jp: "スペックです",
    kr: "규격",
  },
  {
    zh: "款式",
    tw: "款式",
    us: "style",
    jp: "デザインです",
    kr: "스타일",
  },
  {
    zh: "型号",
    tw: "型號",
    us: "Model number",
    jp: "型番です",
    kr: "모델",
  },
  {
    zh: "尺寸",
    tw: "尺寸",
    us: "dimension",
    jp: "サイズです",
    kr: "사이즈",
  },
  {
    zh: "尺码",
    tw: "尺碼",
    us: "size",
    jp: "サイズです",
    kr: "사이즈",
  },
  {
    zh: "颜色",
    tw: "顏色",
    us: "color",
    jp: "色です",
    kr: "색상",
  },
  {
    zh: "材质",
    tw: "材質",
    us: "material",
    jp: "材質です",
    kr: "소재",
  },
  {
    zh: "口味",
    tw: "口味",
    us: "taste",
    jp: "味です",
    kr: "맛",
  },
  {
    zh: "色号",
    tw: "色號",
    us: "Color number",
    jp: "色番号です",
    kr: "색 호",
  },
  {
    zh: "地点",
    tw: "地點",
    us: "location",
    jp: "場所です",
    kr: "장소",
  },
  {
    zh: "货号",
    tw: "貨號",
    us: "Item number",
    jp: "貨物番号です",
    kr: "물품",
  },
  {
    zh: "成分",
    tw: "成分",
    us: "ingredient",
    jp: "成分です",
    kr: "성분",
  },
  {
    zh: "重量",
    tw: "重量",
    us: "weight",
    jp: "重さです",
    kr: "무게",
  },
  {
    zh: "类别",
    tw: "類別",
    us: "category",
    jp: "カテゴリです",
    kr: "종류",
  },
  {
    zh: "品类",
    tw: "品類",
    us: "category",
    jp: "カテゴリーです",
    kr: "종류",
  },
  {
    zh: "网络",
    tw: "網絡",
    us: "network",
    jp: "インターネットです",
    kr: "네트워크",
  },
];

export { sku };
