<template>
  <div class="activity_create">
    <div class="activity_create_back" @click="handleBack">
      <el-button type="text">
        <i class="el-icon-arrow-left"></i>返回游玩项目列表</el-button
      >
    </div>
    <div class="activity_create_title">编辑游玩项目</div>

    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="demo-ruleForm"
      label-position="right"
      label-width="130px"
    >
      <div class="activity_create_bg">
        <h4>选择城市</h4>
        <el-form-item label="选择所在城市 " prop="cityId">
          <el-select
            v-model="ruleForm.cityId"
            filterable
            clearable
            placeholder="请选择发布项目的城市"
            style="margin-left: 0; width: 98%"
            @change="handleChangeSelect($event)"
          >
            <el-option
              v-for="item in optionsCity"
              :key="item.id"
              :label="item.country + '-' + item.city"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择位置经纬度">
          <div style="display: flex">
            <el-button @click="handlePosition">选择位置经纬度</el-button>

            <el-dialog
              title="选择位置经纬度"
              :visible.sync="mapVisible"
              :fullscreen="false"
              id="mapDialog"
              :close-on-click-modal="false"
              :append-to-body="true"
            >
              <div style="margin-bottom: 10px">
                地区：<el-input
                  v-model="location2"
                  class="lineinput"
                  style="width: 200px"
                  size="mini"
                />
                关键词：<el-input
                  v-model="keyword"
                  class="lineinput"
                  style="width: 200px"
                  size="mini"
                />
              </div>
              <baidu-map
                class="map"
                :center="center"
                :zoom="zoom"
                @ready="handler"
                :scroll-wheel-zoom="true"
                @click="clickEvent"
              >
                <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
                <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT"></bm-city-list>
                <bm-geolocation
                  anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
                  :showAddressBar="true"
                  :autoLocation="true"
                  @locationSuccess="getLoctionSuccess"
                ></bm-geolocation>
                <bm-view
                  :style="{
                    width: '100%',
                    height: '300px',
                    flex: 1,
                    marginTop: '0px',
                  }"
                ></bm-view>
                <bm-local-search
                  :keyword="keyword"
                  :auto-viewport="false"
                  :location="location2"
                  style="height: 150px; overflow-y: scroll; margin: 2px 0"
                ></bm-local-search>
              </baidu-map>
              <div class="demo-input-suffix" style="margin-top: 2vh">
                <el-link type="info">经度：</el-link
                ><el-input
                  class="lineinput"
                  style="width: 200px"
                  size="mini"
                  v-model.number="locData.longitude"
                  :disabled="true"
                ></el-input>
                <el-link type="info"> 纬度：</el-link
                ><el-input
                  class="lineinput"
                  style="width: 200px"
                  size="mini"
                  v-model.number="locData.latitude"
                  :disabled="true"
                ></el-input>
                <el-link type="info"> 位置：</el-link
                ><el-input
                  class="lineinput"
                  style="width: 200px"
                  size="mini"
                  v-model="locData.address"
                  :disabled="true"
                ></el-input>
              </div>
              <div
                slot="footer"
                class="dialog-footer"
                style="margin-top: -30px"
              >
                <el-button size="small" @click.native="mapVisible = false"
                  >取消</el-button
                >
                <el-button
                  type="primary"
                  size="small"
                  @click.native="findlocation"
                  >确定</el-button
                >
              </div>
            </el-dialog>

            <el-button @click="handlePositionInput" style="margin-left: 10px"
              >手动输入经纬度</el-button
            >

            <el-dialog
              title="输入经纬度"
              :visible.sync="mapInputVisible"
              :fullscreen="false"
              :close-on-click-modal="false"
              :append-to-body="true"
            >
              <el-input
                placeholder="请输入经度"
                v-model="input.lng"
                style="margin-bottom: 10px"
              ></el-input>
              <el-input placeholder="请输入纬度" v-model="input.lat"></el-input>

              <div slot="footer" class="dialog-footer">
                <el-button size="small" @click.native="mapInputVisible = false"
                  >取消</el-button
                >
                <el-button
                  type="primary"
                  size="small"
                  @click.native="findInputlocation"
                  >确定</el-button
                >
              </div>
            </el-dialog>
          </div>
          <div v-if="locData.longitude && locData.latitude">
            经度：{{ this.locData.longitude }} 纬度：{{ this.locData.latitude }}
          </div>
        </el-form-item>
      </div>

      <div class="activity_create_bg">
        <h4>基础信息</h4>
        <el-form-item label="项目名称" prop="goodsName">
          <el-input
            v-model="ruleForm.goodsName"
            maxlength="60"
            show-word-limit
            placeholder="请输入项目名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="项目位置" prop="goodsLocationName">
          <el-input
            v-model="ruleForm.goodsLocationName"
            placeholder="请输入项目位置"
          ></el-input>
        </el-form-item>
      </div>

      <div class="activity_create_bg">
        <h4>销售信息</h4>

        <div style="padding: 20px 60px">
          <div class="goods-spec">
            <span style="font-size: 14px">
              输入分类名
              <span v-if="this.privateGoodsItem.length == 0">(0/2)</span>
              <span v-if="this.privateGoodsItem.length == 1">(1/2)</span>
              <span v-if="this.privateGoodsItem.length == 2">(2/2)</span>
            </span>
            <el-button
              @click="addPrivateSpec"
              class="goods-spec-add"
              v-if="this.privateGoodsItem.length < 2"
              >添加规格</el-button
            >
          </div>

          <div
            class="goods-container"
            v-for="(attr, index) in privateGoodsItem"
            :key="index"
          >
            <div class="goods-content">
              <div class="goods-content-box">
                <div class="goods-content-left">
                  <el-form label-width="80px">
                    <el-form-item label="规格名">
                      <el-select
                        v-model="attr.privateSpecName.specKey"
                        placeholder="请选择（可输入关键词搜索）"
                        filterable
                        style="width: 250px"
                      >
                        <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      label="规格值"
                      v-if="attr.privateSpecName.specKey"
                    >
                      <el-tag
                        v-for="tag in attr.dynamicTags"
                        :key="tag['specValue']"
                        closable
                        :disable-transitions="false"
                        @close="handleClose(tag['specValue'], attr)"
                      >
                        <el-tooltip placement="top">
                          <div slot="content">
                            中文：{{ tag["specValue"] }}<br />
                            繁体：{{ tag["specValueTw"] }}<br />
                            英文：{{ tag["specValueUs"] }}<br />
                            韩文：{{ tag["specValueKr"] }}<br />
                            日文：{{ tag["specValueJp"] }}<br />
                          </div>
                          <el-tag style="border: none; height: 30px">{{
                            tag["specValue"]
                          }}</el-tag>
                        </el-tooltip>
                      </el-tag>

                      <el-button
                        class="button-new-tag"
                        size="small"
                        v-if="attr.dynamicTags.length < 10"
                        @click="showInput(attr, index)"
                        >+ 添加</el-button
                      >

                      <el-dialog
                        title="添加多语言规格名"
                        :visible.sync="dialogFormVisible"
                      >
                        <el-form :model="form" ref="form" :rules="rules">
                          <el-form-item
                            label="中文："
                            prop="specValue"
                            :label-width="formLabelWidth"
                          >
                            <el-input
                              v-model="form.specValue"
                              autocomplete="off"
                            ></el-input>
                          </el-form-item>
                          <el-form-item
                            label="繁体："
                            :label-width="formLabelWidth"
                          >
                            <el-input
                              v-model="form.specValueTw"
                              autocomplete="off"
                            ></el-input>
                          </el-form-item>
                          <el-form-item
                            label="英文："
                            prop="specValueUs"
                            :label-width="formLabelWidth"
                          >
                            <el-input
                              v-model="form.specValueUs"
                              autocomplete="off"
                            ></el-input>
                          </el-form-item>
                          <el-form-item
                            label="韩文："
                            :label-width="formLabelWidth"
                          >
                            <el-input
                              v-model="form.specValueKr"
                              autocomplete="off"
                            ></el-input>
                          </el-form-item>
                          <el-form-item
                            label="日文："
                            :label-width="formLabelWidth"
                          >
                            <el-input
                              v-model="form.specValueJp"
                              autocomplete="off"
                            ></el-input>
                          </el-form-item>
                        </el-form>
                        <div slot="footer" class="dialog-footer">
                          <el-button @click="dialogFormVisible = false"
                            >取 消</el-button
                          >
                          <el-button type="primary" @click="setFormData('form')"
                            >确 定</el-button
                          >
                        </div>
                      </el-dialog>
                    </el-form-item>
                  </el-form>
                </div>
                <div class="goods-content-right">
                  <el-link type="danger" @click="delPrivateSpec(index)"
                    >删除规格</el-link
                  >
                </div>
              </div>
            </div>
          </div>

          <p style="margin: 24px 0 10px 0">销售规格</p>
          <el-table
            :data="tableColumnList.tableBodyList"
            style="width: 100%; margin-top: 1%"
            align="center"
          >
            <el-table-column
              type="index"
              label="序号"
              width="80"
              align="center"
            >
              <template slot-scope="scope">
                <span>{{
                  scope.$index + (currentPage - 1) * pageSize + 1
                }}</span>
              </template>
            </el-table-column>

            <el-table-column
              :label="item.propName"
              :property="item.prop"
              v-for="item in tableColumnList.tableHeaderList"
              :key="item.prop"
              align="center"
              width="200"
            >
              <template slot-scope="scope">
                <span>{{ scope.row[scope.column.property] }}</span>
              </template>
            </el-table-column>
            <el-table-column label="价格" align="center" width="280">
              <template slot-scope="scope">
                <el-input-number
                  v-model.number="scope.row.basePrice"
                  :precision="2"
                  :step="0.1"
                ></el-input-number>
                <div class="create_table">
                  <div>
                    <p>A级专属价</p>
                    <p>
                      {{ scope.row.aDiscount || 10 }}折<i
                        class="el-icon-edit"
                        style="cursor: pointer"
                        @click="handleEditPrice('a', scope.row)"
                      ></i>
                    </p>
                  </div>
                  <div>
                    <p>B级专属价</p>
                    <p>
                      {{ scope.row.bDiscount || 10 }}折<i
                        class="el-icon-edit"
                        style="cursor: pointer"
                        @click="handleEditPrice('b', scope.row)"
                      ></i>
                    </p>
                  </div>
                  <div>
                    <p>C级专属价</p>
                    <p>
                      {{ scope.row.cDiscount || 10 }}折<i
                        class="el-icon-edit"
                        style="cursor: pointer"
                        @click="handleEditPrice('c', scope.row)"
                      ></i>
                    </p>
                  </div>
                  <div>
                    <p>D级专属价</p>
                    <p>
                      {{ scope.row.dDiscount || 10 }}折<i
                        class="el-icon-edit"
                        style="cursor: pointer"
                        @click="handleEditPrice('d', scope.row)"
                      ></i>
                    </p>
                  </div>

                  <el-dialog
                    title="设置专属折扣"
                    :append-to-body="true"
                    :visible.sync="dialogFormVisiblePrice"
                  >
                    <el-form :model="exclusivePrice">
                      <p style="margin: 0 0 10px 30px; color: #f56c6c">
                        ps：输入8.5 则为八五折，输入9 则为九折
                      </p>
                      <el-form-item
                        label="A级专属折扣"
                        :label-width="formLabelWidth"
                        v-if="priceType == 1"
                      >
                        <el-input-number
                          style="width: 300px"
                          v-model="exclusivePrice.aDiscount"
                          :min="0"
                          :max="10"
                          :step="0.1"
                          autocomplete="off"
                        ></el-input-number>
                      </el-form-item>
                      <el-form-item
                        label="B级专属折扣"
                        :label-width="formLabelWidth"
                        v-else-if="priceType == 2"
                      >
                        <el-input-number
                          style="width: 300px"
                          v-model="exclusivePrice.bDiscount"
                          :min="0"
                          :max="10"
                          :step="0.1"
                          autocomplete="off"
                        ></el-input-number>
                      </el-form-item>
                      <el-form-item
                        label="C级专属折扣"
                        :label-width="formLabelWidth"
                        v-else-if="priceType == 3"
                      >
                        <el-input-number
                          style="width: 300px"
                          v-model="exclusivePrice.cDiscount"
                          :min="0"
                          :max="10"
                          :step="0.1"
                          autocomplete="off"
                        ></el-input-number>
                      </el-form-item>
                      <el-form-item
                        label="D级专属折扣"
                        :label-width="formLabelWidth"
                        v-else-if="priceType == 4"
                      >
                        <el-input-number
                          style="width: 300px"
                          v-model="exclusivePrice.dDiscount"
                          :min="0"
                          :max="10"
                          :step="0.1"
                          autocomplete="off"
                        ></el-input-number>
                      </el-form-item>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                      <el-button @click="dialogFormVisiblePrice = false"
                        >取 消</el-button
                      >
                      <el-button type="primary" @click="handleSetExclusiveTrue"
                        >确 定</el-button
                      >
                    </div>
                  </el-dialog>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="库存" align="center" width="230">
              <template slot-scope="scope">
                <el-input-number
                  v-model.number="scope.row.baseStock"
                  :min="0"
                ></el-input-number>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              :required="true"
              label="指定日期价格"
              width="130"
            >
              <template slot-scope="{ row, $index }">
                <el-button type="text" @click="handleSetDatePrice(row, $index)"
                  >设置</el-button
                >
                <el-dialog
                  title="指定日期价格"
                  :visible.sync="dialogDatePriceVisible"
                  :append-to-body="true"
                >
                  <div>
                    <el-table
                      :data="tableDataPrice"
                      :row-class-name="rowClassNamePrice"
                      style="width: 100%"
                      border
                    >
                      <el-table-column
                        prop="mescode"
                        align="center"
                        :required="true"
                        label="日期"
                      >
                        <template slot-scope="{ row, $index }">
                          <div v-if="typeof row.goodsDate == 'object'">
                            <div v-for="(item, i) in row.goodsDate" :key="i">
                              <span v-if="!showEditPrice[$index]">{{
                                item
                              }}</span>
                            </div>
                          </div>
                          <div v-if="typeof row.goodsDate == 'string'">
                            <span v-if="!showEditPrice[$index]">
                              {{ row.goodsDate }}</span
                            >
                          </div>

                          <div>
                            <el-date-picker
                              type="dates"
                              v-model="tableDataPrice[row.xh - 1].goodsDate"
                              value-format="yyyy-MM-dd"
                              placeholder="选择⼀个或多个⽇期"
                              v-if="showEditPrice[$index]"
                            ></el-date-picker>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="basePrice"
                        align="center"
                        :required="true"
                        label="价格"
                      >
                        <template slot-scope="{ row, $index }">
                          <span v-if="!showEditPrice[$index]">{{
                            row.basePrice
                          }}</span>
                          <el-input-number
                            v-model="tableDataPrice[row.xh - 1].basePrice"
                            v-if="showEditPrice[$index]"
                          ></el-input-number>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="stock"
                        align="center"
                        :required="true"
                        label="库存"
                      >
                        <template slot-scope="{ row, $index }">
                          <span v-if="!showEditPrice[$index]">{{
                            row.stock
                          }}</span>
                          <el-input-number
                            v-model="tableDataPrice[row.xh - 1].stock"
                            v-if="showEditPrice[$index]"
                          ></el-input-number>
                        </template>
                      </el-table-column>

                      <el-table-column
                        header-align="center"
                        align="center"
                        width="100"
                        label="操作"
                      >
                        <template slot-scope="{ row, $index }">
                          <el-button
                            v-if="!showEditPrice[$index]"
                            @click="showUpdatePrice($index, row)"
                            type="text"
                            size="small"
                            >编辑</el-button
                          >
                          <el-button
                            v-if="!showEditPrice[$index]"
                            @click="handleDeleteBtnPrice($index, row)"
                            type="text"
                            size="small"
                            style="color: red"
                            >删除</el-button
                          >

                          <el-button
                            v-if="showEditPrice[$index]"
                            @click="submitKeepPrice($index, row)"
                            type="text"
                            size="small"
                            style="color: #85ce61"
                            >确定</el-button
                          >
                          <el-button
                            v-if="showEditPrice[$index]"
                            @click="cancelUpdatePrice($index)"
                            type="text"
                            size="small"
                            style="color: red"
                            >取消</el-button
                          >
                        </template>
                      </el-table-column>
                    </el-table>

                    <el-button
                      type="success"
                      icon="el-icon-plus"
                      size="mini"
                      @click="handleAddBtnPrice"
                      >添加自选日期</el-button
                    >
                  </div>

                  <span slot="footer" class="dialog-footer">
                    <el-button
                      type="primary"
                      @click="handleAddBtnPriceSubmit(row)"
                      >确 定</el-button
                    >
                  </span>
                </el-dialog>
              </template>
            </el-table-column>
            <el-table-column label="状态" align="center">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.isVisible"
                  active-text="上架"
                  inactive-text="下架"
                >
                </el-switch>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div class="activity_create_bg">
        <h4>图文描述</h4>

        <el-form-item label="主图图片 " prop="img">
          <p style="color: #000000; opacity: 0.5; margin-left: 0">
            上传1:1的图片,大小不能超过
            10MB，最多12张（默认第一张图片为封面主图）
          </p>
          <div class="video_upload">
            <el-upload
              :action="UploadUrl()"
              ref="uploadimg"
              :limit="12"
              :auto-upload="false"
              :on-change="imageChange"
              :show-file-list="true"
              :file-list="[]"
              list-type="picture-card"
              :on-exceed="handleExceed"
              :class="{ disabled: uploadDisabled }"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-progress
              v-show="progressFlagImg"
              :percentage="loadProgressImg"
            ></el-progress>

            <!-- 拖拽组件 -->
            <draggable
              v-model="zlist"
              :animation="100"
              class="dira"
              @update="handleDraggable"
            >
              <div class="isblocks" v-for="(item, index) in zlist" :key="index">
                <div class="ress">
                  <el-image
                    ref="preview"
                    :style="{
                      width: upWith + 'px',
                      height: upHeight + 'px',
                      borderRadius: upBradius + 'px',
                    }"
                    :preview-src-list="zlist"
                    :src="item"
                  ></el-image>
                  <div class="imgs_prews">
                    <div
                      class="imgs_prew"
                      :style="{
                        width: upWith + 'px',
                        height: upHeight + 'px',
                        borderRadius: upBradius + 'px',
                      }"
                    >
                      <i @click="ylimg(index)" class="el-icon-view"></i>
                      <span></span>
                      <i @click="deleteimg(index)" class="el-icon-delete"></i>
                    </div>
                  </div>
                </div>
              </div>
            </draggable>
          </div>
        </el-form-item>

        <el-form-item label="上传视频 " prop="video">
          <p style="color: #000000; opacity: 0.5; margin-left: 0">
            大小不能超过 600MB
          </p>

          <div class="video_upload">
            <el-upload
              ref="video"
              list-type="picture-card"
              v-model="ruleForm.videoPath"
              :action="UploadUrl()"
              :show-file-list="false"
              :on-change="imageChangeVideo"
              :on-progress="uploadVideoProcess"
              :http-request="UploadVideo"
            >
              <i class="el-icon-upload"></i>
            </el-upload>

            <div class="list-vedio">
              <div v-for="(item, index) in fileListVideo" :key="index">
                <video
                  v-if="ruleForm.videoPath != '' && videoFlag == false"
                  :src="item"
                  class="avatar"
                  controls="controls"
                >
                  您的浏览器不支持视频播放
                </video>
                <el-button type="danger" @click="handleRemoveVideo(index)"
                  >删除该视频</el-button
                >
              </div>
            </div>

            <el-progress
              v-show="progressFlag"
              :percentage="loadProgress"
            ></el-progress>
          </div>
        </el-form-item>

        <el-form-item
          label="详情描述"
          prop="hotelLinkEdit"
          class="rich_z-index"
        >
          <div id="richText"></div>

          <div class="el-form_btn_b_save">
            <el-button @click="handleSaveShelves('zh')">保存草稿</el-button>
            <div class="el-form_btn_b_save_div" v-if="isSave">
              <el-button
                type="success"
                icon="el-icon-check"
                size="mini"
                circle
              ></el-button
              >最后保存于{{ getTimeSave }}
            </div>
          </div>
        </el-form-item>
      </div>

      <div class="activity_create_bg">
        <h4>服务政策</h4>

        <el-form-item label="退定政策" prop="policy">
          <el-radio-group v-model="ruleForm2.policy">
            <el-radio :label="0">不可退订</el-radio>
            <el-radio label="ok">可退订</el-radio>
          </el-radio-group>

          <el-select
            v-model="ruleForm2.policyOk"
            placeholder="请选择"
            v-if="ruleForm2.policy == 'ok'"
            @change="handleChangeSelect($event, 'cancel')"
          >
            <el-option
              v-for="item in cancelOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>

      <div class="activity_create_bg">
        <h4>是否上架</h4>
        <el-form-item label="是否上架" prop="isVisible">
          <el-radio-group v-model="ruleForm.isVisible">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>

      <div class="submit">
        <el-button @click="handleSubmit('ruleForm')" type="primary"
          >提交商品信息</el-button
        >
      </div>
    </el-form>
  </div>
</template>

<script>
import axios from "axios";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { hideLoading, showLoading } from "@/util/loading";

import { sku } from "@/util/LanguageData";
import Breadcrumb from "@/components/BreadCrumb";
import E from "wangeditor"; //引入富文本插件

import draggable from "vuedraggable";

import {
  format_time_date_noTime,
  removeTrailingZeros,
} from "@/util/funBusiness";

let editor;

export default {
  components: { draggable },
  props: {
    //宽度
    upWith: {
      type: Number,
      default: 100,
    },
    //高度
    upHeight: {
      type: Number,
      default: 100,
    },
    //圆角
    upBradius: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      location2: "",
      keyword: "",
      center: { lng: 111.752912, lat: 40.832246 },
      zoom: 12,
      mapVisible: false,
      locData: {
        longitude: "",
        latitude: "",
        address: "",
      },
      temp: {
        location: "",
        lng: "",
        lat: "",
      },
      mapInputVisible: false,
      input: {
        lng: "",
        lat: "",
      },
      optionsCity: [],

      ruleForm: {
        cityId: "",
        goodsName: "",
        goodsLocationName: "",
        isVisible: true,
        videoPath: "",
      },
      ruleForm2: {
        policy: 0,
        policyOk: 1,
      },

      rules: {
        cityId: [
          {
            required: true,
            message: "请选择发布项目的城市",
            trigger: "blur",
          },
        ],
        goodsName: [
          { required: true, message: "请输入项目名称", trigger: "blur" },
          { min: 0, max: 60, message: "已超出字数限制", trigger: "blur" },
        ],
        goodsLocationName: [
          { required: true, message: "请输入项目位置", trigger: "blur" },
        ],
        position: [
          { required: true, message: "请选择经纬度", trigger: "blur" },
        ],
      },

      options: [
        {
          value: "场次",
          label: "场次",
        },
        {
          value: "型号",
          label: "型号",
        },
        {
          value: "款式",
          label: "款式",
        },
        {
          value: "尺寸",
          label: "尺寸",
        },
        {
          value: "尺码",
          label: "尺码",
        },
        {
          value: "颜色",
          label: "颜色",
        },
        {
          value: "材质",
          label: "材质",
        },
        {
          value: "口味",
          label: "口味",
        },
        {
          value: "色号",
          label: "色号",
        },
        {
          value: "地点",
          label: "地点",
        },
        {
          value: "货号",
          label: "货号",
        },
        {
          value: "成分",
          label: "成分",
        },
        {
          value: "重量",
          label: "重量",
        },
        {
          value: "类别",
          label: "类别",
        },
        {
          value: "品类",
          label: "品类",
        },
        {
          value: "网络",
          label: "网络",
        },
      ],

      cancelOptions: [
        {
          value: 0,
          label: "不可退订",
        },
        {
          value: 1,
          label: "提前1天退订",
        },
        {
          value: 2,
          label: "提前3天退订",
        },
        {
          value: 3,
          label: "提前7天退订",
        },
        {
          value: 4,
          label: "提前14天退订",
        },
      ],

      tableColumnList: {
        tableHeaderList: [],
        tableBodyList: [],
      },

      privateGoodsItem: [
        {
          privateSpecName: {}, //规格名
          dynamicTags: [], //规格值数组
          inputVisible: false,
          inputValue: "",
        },
      ],
      urlTableData: [],
      skuList: [],
      stockNum: "", // 修改库存数量
      priceNum: "", // 修改价格

      fileList: [],
      fileListImg: [],
      uploadDisabled: false,
      isSave: false,
      getTimeSave: "",
      progressFlagImg: false,
      loadProgressImg: 0,
      zlist: [], //组件数组

      // 视频相关
      fileListVideo: [],
      videoFlag: false,
      // //是否显示进度条
      videoUploadPercent: 0,
      progressFlag: false,
      loadProgress: 0,

      showInputAttr: "",

      dialogFormVisible: false,
      dialogFormVisiblePrice: false,
      dialogDatePriceVisible: false,
      dialogDatePriceVisibleIndex: 0,

      // 指定日期价格
      goodsDate: "",
      tableDataPrice: [],
      tableDataPriceList: [],
      showEditPrice: [], //控制显示及隐藏

      form: {
        specValue: "",
        specValueTw: "",
        specValueUs: "",
        specValueKr: "",
        specValueJp: "",
      },
      formLabelWidth: "120px",
      // 分页数据
      currentPage: 1, // 当前页
      pageSize: 10, //要传过去的数据 每页多少条数据

      exclusivePrice: {
        aDiscount: 10,
        bDiscount: 10,
        cDiscount: 10,
        dDiscount: 10,
      },
      priceType: 1,
      rowExclusivePrice: {},

      dataRes: {},
      queryRoute: "",

      ids: "",

      deleteList: [],
      dataCopyInfo: {},
      dataInxInfo: 0,
    };
  },

  computed: {
    // 计算规格
    calculateAttribute() {
      // 初始化
      let obj = {};

      this.privateGoodsItem.forEach((item) => {
        console.log(item, "计算中");
        // 判断有没有输入规格名
        if (item.privateSpecName.specKey) {
          //规格名:规格值     //'颜色':'尺寸'
          obj[item.privateSpecName.specKey] = item.dynamicTags;
        }
      });
      return obj;
    },
  },

  watch: {
    fileListImg(newvalue) {
      this.fileListImg = newvalue;
    },

    // 监听规格数据
    calculateAttribute(newVal) {
      let cloneNewVal = JSON.parse(JSON.stringify(newVal));
      let skuTableArr = Object.keys(newVal);

      let attrName = []; //规格名数组
      let attrValue = []; //规格值数组
      for (let key in cloneNewVal) {
        attrName.push(key);
        attrValue.push(cloneNewVal[key]);
      }

      // 表格内容数据（笛卡尔积算法）
      let finalArr = this.cartesianProductOf(...attrValue);

      if (finalArr != undefined) {
        let tableObj = {
          tableBodyList: [],
          tableHeaderList: [],
        };

        // 表格内容
        tableObj.tableBodyList = finalArr.map((item, i) => {
          let obj = {
            basePrice: 0,
            baseStock: 0,
            isVisible: 0,
            aDiscount: 10,
            bDiscount: 10,
            cDiscount: 10,
            dDiscount: 10,
            skuList: [],
            id:
              this.$route.query.type == "edit"
                ? this.dataRes.specInfoAdminList.forEach((it) => it.id)
                : null,
          };

          let str = "";

          if (typeof item === "string") {
            str = item;
          } else {
            str = item.specValue;
          }

          let ids = [];
          this.dataRes.specInfoAdminList.forEach((it) => {
            ids.push(it.id);
          });

          let ftemData =
            this.urlTableData.filter((ftem) => ftem.specAttr == str)[0] || {};
          obj.basePrice = ftemData.basePrice || "0.00";
          obj.baseStock = ftemData.baseStock || 0;
          obj.isVisible = ftemData.isVisible == 0 ? false : true;
          obj.aDiscount = ftemData.aDiscount || 10;
          obj.bDiscount = ftemData.bDiscount || 10;
          obj.cDiscount = ftemData.cDiscount || 10;
          obj.dDiscount = ftemData.dDiscount || 10;
          obj.skuList = this.tableDataPrice;
          obj.id = ids[i];

          let arr = str.split(",");

          for (let i = 0; i < arr.length; i++) {
            obj[attrName[i]] = arr[i];
          }

          return obj;
        });

        this.tableColumnList.tableBodyList = tableObj.tableBodyList; //表格内容数据
        // 表头
        tableObj.tableHeaderList = skuTableArr.map((item) => {
          return {
            prop: item,
            propName: item,
          };
        });
        this.tableColumnList.tableHeaderList = tableObj.tableHeaderList; // 表头

        this.dataRes.specInfoAdminList.map((item, index) => {
          const {
            aDiscount,
            bDiscount,
            basePrice,
            baseStock,
            cDiscount,
            dDiscount,
            isVisible,
          } = item;
          this.tableColumnList.tableBodyList.forEach(
            (tableItem, tableIndex) => {
              if (index == tableIndex) {
                tableItem.aDiscount = (aDiscount * 10).toFixed(1);
                tableItem.bDiscount = (bDiscount * 10).toFixed(1);
                tableItem.cDiscount = (cDiscount * 10).toFixed(1);
                tableItem.dDiscount = (dDiscount * 10).toFixed(1);
                tableItem.basePrice = basePrice;
                tableItem.baseStock = baseStock;
                tableItem.isVisible = isVisible;
              }
            }
          );
        });
      }
    },
  },

  created() {
    //回显数组
    this.zlist = this.fileListImg;
  },

  mounted() {
    setTimeout(() => {
      // 中文
      editor = new E("#richText");
      editor.config.uploadImgServer = reqUrl.richImageUpload;
      editor.config.uploadImgMaxSize = 20 * 1024 * 1024;
      editor.config.uploadFileName = "file";
      editor.create();
    }, 100);

    const opt = {
      url: reqUrl.worldTravelSelectCity,
      method: "POST",
      params: JSON.stringify({
        city: "",
        country: "",
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
        } else if (res.data.code == 0) {
          this.optionsCity = res.data.data.list;
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
      },
    };
    request(opt);

    this.queryRoute = this.$route.query.type;

    const optEdit = {
      url: reqUrl.worldGetSightseeingInfoDetailById,
      method: "POST",
      params: JSON.stringify({
        sightseeingId: this.$route.query.sightseeingId,
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
        } else if (res.data.code == 0) {
          this.dataRes = res.data.data;

          if (this.dataRes.detailLinkEdit) {
            setTimeout(() => {
              editor.txt.html(this.dataRes.detailLinkEdit);
            }, 100);
          }
          this.ruleForm = this.dataRes;
          this.locData.longitude = this.dataRes.goodsLocationLng;
          this.locData.latitude = this.dataRes.goodsLocationLat;

          let images = this.dataRes.images.split(",");
          console.log(images, "imagesimages");
          this.zlist = images;

          if (this.dataRes.videos != null) {
            let videos = this.dataRes.videos.split(",");
            this.fileListVideo = videos;
          }

          for (var i = 0; i < images.length; i++) {
            var str = images[i];
            this.fileList.push({ url: str });
          }

          this.tableColumnList.tableBodyList = this.dataRes.specInfoAdminList;

          if (this.dataRes.cancelRule != 0) {
            var obj = { policy: "ok", policyOk: this.dataRes.cancelRule };
            this.ruleForm2 = obj;
            this.$forceUpdate();
          } else {
            var obj = { policy: this.dataRes.cancelRule };
            this.ruleForm2 = obj;
            this.$forceUpdate();
          }

          if (this.dataRes.specList) {
            let arr = [];
            res.data.data.specList.forEach((item, i) => {
              let obj = {};
              obj.privateSpecName = item.specKey;

              obj.dynamicTags = item.speckValueList;
              obj.inputVisible = false;
              arr.push(obj);
            });
            this.privateGoodsItem = arr;
          }
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
      },
    };
    request(optEdit);
  },

  methods: {
    handleBack() {
      this.$router.push("/worldPlayList");
    },

    //地图
    handlePosition() {
      this.mapVisible = true;
    },

    handlePositionInput() {
      this.mapInputVisible = true;
    },

    handler({ BMap, map }) {
      let _this = this; // 设置一个临时变量指向vue实例，因为在百度地图回调里使用this，指向的不是vue实例；
      let geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition(
        function (r) {
          console.log(r);
          _this.center = { lng: r.longitude, lat: r.latitude }; // 设置center属性值
          _this.autoLocationPoint = { lng: r.longitude, lat: r.latitude }; // 自定义覆盖物
          _this.initLocation = true;
        },
        { enableHighAccuracy: true }
      );
      window.map = map;
    },

    //点击地图监听
    clickEvent(e) {
      map.clearOverlays();
      let Icon_0 = new BMap.Icon(
        "http://api0.map.bdimg.com/images/marker_red_sprite.png",
        new BMap.Size(64, 64),
        { anchor: new BMap.Size(18, 32), imageSize: new BMap.Size(36, 25) }
      );
      let myMarker = new BMap.Marker(new BMap.Point(e.point.lng, e.point.lat), {
        icon: Icon_0,
      });
      map.addOverlay(myMarker);
      //用所定位的经纬度查找所在地省市街道等信息
      let point = new BMap.Point(e.point.lng, e.point.lat);
      let gc = new BMap.Geocoder();
      let _this = this;
      gc.getLocation(point, function (rs) {
        let addComp = rs.addressComponents;
        _this.locData.address = rs.address;
      });
      this.locData.longitude = e.point.lng;
      this.locData.latitude = e.point.lat;
    },

    //定位成功回调
    getLoctionSuccess(point, AddressComponent, marker) {
      map.clearOverlays();
      let Icon_0 = new BMap.Icon(
        "http://api0.map.bdimg.com/images/marker_red_sprite.png",
        new BMap.Size(64, 64),
        { anchor: new BMap.Size(18, 32), imageSize: new BMap.Size(36, 36) }
      );
      let myMarker = new BMap.Marker(
        new BMap.Point(point.point.lng, point.point.lat),
        { icon: Icon_0 }
      );
      map.addOverlay(myMarker);
      this.locData.longitude = point.point.lng;
      this.locData.latitude = point.point.lat;
    },

    //返回选中点的位置
    findlocation() {
      this.temp.location = this.keyword;
      this.temp.lng = this.locData.longitude;
      this.temp.lat = this.locData.latitude;
      this.mapVisible = false;
    },

    // 手动输入-返回经纬度位置
    findInputlocation() {
      this.locData.longitude = this.input.lng;
      this.locData.latitude = this.input.lat;
      this.mapInputVisible = false;
    },

    // 添加规格
    addPrivateSpec(index) {
      this.privateGoodsItem.push({
        privateSpecName: {
          specKey: "",
        },
        dynamicTags: [],
        inputVisible: false,
        inputValue: "",
      });
    },

    delPrivateSpec(index) {
      this.privateGoodsItem.splice(index, 1);
    },

    handleInputConfirm(val, attr) {
      if (val) {
        attr.dynamicTags.push({ specValue: val });
      }
      attr.inputVisible = false;
      attr.inputValue = "";
    },

    handleClose(tag, item) {
      item.dynamicTags.forEach((ytem, y) => {
        if (ytem["specValue"] == tag) {
          item.dynamicTags.splice(y, 1);
        }
      });
    },

    showInput(attr, index) {
      this.showInputAttr = attr;
      this.dialogFormVisible = true;

      this.form.specValue = "";
      this.form.specValueTw = "";
      this.form.specValueUs = "";
      this.form.specValueKr = "";
      this.form.specValueJp = "";
    },

    // 设置语言
    setFormData(formName) {
      this.$refs[formName][0].validate((valid) => {
        if (valid) {
          let langObj = this.form;
          if (langObj) {
            this.showInputAttr.dynamicTags.push(
              JSON.parse(JSON.stringify(langObj))
            );
          }
          this.dialogFormVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    // 笛卡尔积算法
    cartesianProductOf(...args) {
      if (args.length > 0) {
        return args.reduce((total, current) => {
          let ret = [];
          total.forEach((a) => {
            current.forEach((b) => {
              ret.push(a.specValue + "," + b.specValue);
            });
          });
          return ret;
        });
      }
    },

    // 设置专属价格
    handleEditPrice(type, row) {
      this.dialogFormVisiblePrice = true;
      if (type == "a") {
        this.priceType = 1;
      } else if (type == "b") {
        this.priceType = 2;
      } else if (type == "c") {
        this.priceType = 3;
      } else if (type == "d") {
        this.priceType = 4;
      }
      this.exclusivePrice.aDiscount = row.aDiscount;
      this.exclusivePrice.bDiscount = row.bDiscount;
      this.exclusivePrice.cDiscount = row.cDiscount;
      this.exclusivePrice.dDiscount = row.dDiscount;
      this.rowExclusivePrice = row;
    },

    handleSetExclusiveTrue() {
      this.dialogFormVisiblePrice = false;
      console.log(this.exclusivePrice);
      this.rowExclusivePrice.aDiscount = this.exclusivePrice.aDiscount;
      this.rowExclusivePrice.bDiscount = this.exclusivePrice.bDiscount;
      this.rowExclusivePrice.cDiscount = this.exclusivePrice.cDiscount;
      this.rowExclusivePrice.dDiscount = this.exclusivePrice.dDiscount;
    },

    // 指定日期价格
    handleSetDatePrice(row, index) {
      this.dialogDatePriceVisibleIndex = index;

      this.dataRes.specInfoAdminList.forEach((item, i) => {
        if (i === index) {
          this.tableDataPrice = item.skuList;
          item.skuList.map((roomItem) => {
            if (typeof roomItem.goodsDate !== "object") {
              roomItem.goodsDate = format_time_date_noTime(roomItem.goodsDate);
            }
          });
        } else if (index >= (this.dataRes.specInfoAdminList || []).length) {
          this.tableDataPrice = [];
        }
      });

      this.dialogDatePriceVisible = true;
    },
    rowClassNamePrice({ row, rowIndex }) {
      row.xh = rowIndex + 1;
    },
    handleAddBtnPrice() {
      let obj = {};
      obj.goodsDate = "";
      obj.basePrice = 0;
      obj.stock = 0;

      this.tableDataPrice.push(obj);
    },
    showUpdatePrice(index, row) {
      this.showEditPrice[index] = true;
      this.$set(this.showEditPrice, index, true); //这里要用$set方法，否则页面状态不更新
    },
    cancelUpdatePrice(index) {
      this.$set(this.showEditPrice, index, false);
    },
    submitKeepPrice(index, row) {
      //发送请求，隐藏输入框
      this.$message({
        type: "success",
        message: "已缓存，记得点击保存提交修改喔！",
        duration: 888,
        onClose: () => {
          this.$set(this.showEditPrice, index, false); //vue添加属性的方法
        },
      });
      console.log(this.tableDataPrice, "111ssssww_____111");
      // this.tableDataPrice[index] = this.tableDataPriceList;
    },
    handleDeleteBtnPrice(index, val) {
      this.$confirm("请确认是否要删除该项", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.tableDataPrice.forEach((v, i) => {
          if (val.xh === v.xh) {
            this.tableDataPrice.splice(i, 1);
          }
        });
      });
    },
    handleAddBtnPriceSubmit() {
      this.dialogDatePriceVisible = false;

      let arr = [];

      this.tableDataPrice.map((item, index) => {
        const { basePrice, goodsDate, stock, ...item1 } = item;
        if (typeof item.goodsDate !== "string") {
          item.goodsDate.forEach((date) =>
            arr.push({
              goodsDate: date,
              basePrice: item.basePrice.toString(),
              stock: item.stock,
              ...item1,
            })
          );
        } else {
          arr.push({
            goodsDate: item.goodsDate,
            basePrice: item.basePrice.toString(),
            stock: item.stock,
            ...item1,
          });
        }
      });

      if (this.dataRes.specInfoAdminList[this.dialogDatePriceVisibleIndex]) {
        this.dataRes.specInfoAdminList[
          this.dialogDatePriceVisibleIndex
        ].skuList = arr;
      } else {
        this.dataRes.specInfoAdminList[this.dialogDatePriceVisibleIndex] = {
          skuList: arr,
        };
      }
      this.tableColumnList.tableBodyList[
        this.dialogDatePriceVisibleIndex
      ].skuList = arr;

      // 为每个日期创建一个新对象
    },

    UploadUrl() {
      return reqUrl.imageUpload;
    },

    //选择图片后做图片格式限制（手动上传图片时，before-upload钩子无效，使用此方法替代）
    imageChange(file, fileList) {
      this.progressFlagImg = true;
      this.loadProgressImg = 0;
      const interval = setInterval(() => {
        if (this.loadProgressImg >= 99) {
          clearInterval(interval);
          return;
        }
        this.loadProgressImg += 1;
      }, 20);

      if (fileList.length >= 12) {
        this.uploadDisabled = true;
      }
      axios.get(file.url, { responseType: "blob" }).then((res) => {
        const formData = new FormData();
        formData.append("file", res.data);
        formData.append("userId", 1);
        formData.append("scene", 2);

        axios.defaults.headers.post["Authorization"] = localStorage.getItem(
          "resHeadersAuthorization"
        );
        axios({
          method: "POST",
          url: reqUrl.imageUpload,
          header: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        }).then((res) => {
          // this.fileListImg.push(res.data.data.url);
          this.zlist.push(res.data.data.url);

          this.progressFlagImg = false;
          this.loadProgress = 100;
        });
      });

      const isImage =
        file.raw.type == "image/png" ||
        file.raw.type == "image/jpg" ||
        file.raw.type == "image/jpeg";
      const isLt5M = file.size < 1024 * 1024 * 10;
      if (!isImage) {
        this.$message.error("上传只能是png,jpg,jpeg格式!");
      }
      if (!isLt5M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }

      if (isImage && isLt5M) {
        this.uploadFile = file.raw || null;
      } else {
        fileList.splice(-1, 1);
      }
    },

    //超出限制个数钩子
    handleExceed(files) {
      this.$message.warning("当前限制选择12个文件，请删除图片后重新添加");
    },

    handleDraggable(event) {
      const { oldIndex, newIndex } = event;

      this.fileListImg.splice(
        newIndex,
        0,
        this.fileListImg.splice(oldIndex, 1)[0]
      );
    },

    //删除
    deleteimg(index) {
      this.$delete(this.zlist, index);
    },
    //预览
    ylimg(index) {
      this.$refs.preview[index].showViewer = true;
    },

    //视频上传
    UploadVideo(params) {
      this.ruleForm.videoPath = URL.createObjectURL(params.file);
      this.progressFlag = true;
      this.loadProgress = 0;
      const interval = setInterval(() => {
        if (this.loadProgress >= 99) {
          clearInterval(interval);
          return;
        }
        this.loadProgress += 1;
      }, 20);
    },

    imageChangeVideo(file, fileList) {
      axios.get(file.url, { responseType: "blob" }).then((res) => {
        const formData = new FormData();
        formData.append("file", res.data);
        formData.append("userId", 1);
        formData.append("scene", 2);

        axios.defaults.headers.post["Authorization"] = localStorage.getItem(
          "resHeadersAuthorization"
        );
        axios({
          method: "POST",
          url: reqUrl.imageUpload,
          header: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        }).then((res) => {
          this.fileListVideo.push(res.data.data.url);
          this.loadProgress = 100;
        });
      });

      const isLt5M = file.size < 1024 * 1024 * 600;

      if (!isLt5M) {
        this.$message.error("上传图片大小不能超过 600MB!");
      }

      if (isLt5M) {
        this.uploadFile = file.raw || null;
      } else {
        fileList.splice(-1, 1);
      }
    },

    // 删除视频
    handleRemoveVideo(index) {
      this.fileListVideo.splice(index, 1);
    },

    uploadVideoProcess(event, file, fileList) {
      this.videoFlag = true;
      this.videoUploadPercent = file.percentage.toFixed(0) * 1;
    },

    handleSaveShelves(type) {
      if (type == "zh") {
        const opt = {
          url: reqUrl.goodsStringToFileUploadUrl,
          method: "POST",
          params: JSON.stringify({
            fileString: editor.txt.html(),
            title: this.ruleForm.goodsName,
          }),
          resFunc: (res) => {
            console.log(res.data);
            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
            } else if (res.data.code == 0) {
              this.$message({
                message: "保存成功",
                type: "success",
              });
              this.isSave = true;
              this.getTimeSave = new Date().toLocaleString();

              localStorage.setItem("htmlUrlPlayZh", res.data.data.url);
            }
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
          },
        };
        request(opt);
      }
    },

    handleChangeSelect(e, type) {
      if (type == "city") {
        this.$set(this.ruleForm, "cityId", e);
      }
      if (type == "cancel") {
        this.$set(this.ruleForm2, "policyOk", e);
      }
      this.$forceUpdate();
    },

    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.locData.longitude !== "" && this.locData.latitude !== "") {
            this.$confirm("请问您是否确认提交商品信息？", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                let arrs = [];
                let keysObj = {};
                this.privateGoodsItem.forEach((item) => {
                  let objs = {};
                  objs.specKey = item.privateSpecName;
                  let obj =
                    sku.filter((item) => item.zh === objs.specKey.specKey)[0] ||
                    {};
                  objs.specKey.specKeyJp = obj.jp;
                  objs.specKey.specKeyTw = obj.tw;
                  objs.specKey.specKeyKr = obj.kr;
                  objs.specKey.specKeyUs = obj.us;

                  objs.speckValueList = item.dynamicTags;
                  arrs.push(objs);
                  keysObj = objs;
                });

                let arrList = [];
                this.tableColumnList.tableBodyList.forEach((item, i) => {
                  const {
                    basePrice,
                    isVisible,
                    baseStock,
                    aDiscount,
                    bDiscount,
                    cDiscount,
                    dDiscount,
                    skuList,
                    id,
                    ...keyVal
                  } = item;

                  const skuLists =
                    this.dataRes.specInfoAdminList[i].skuList || [];

                  let vals = Object.values(keyVal).join(",");
                  let obj = {};
                  let itemData =
                    ((keysObj.speckValueList || []).filter(
                      (jtem, j) => jtem.specValue === vals
                    ) || [])[0] || {};

                  obj.specAttr = vals;
                  obj.specAttrTw = itemData.specValueTw;
                  obj.specAttrUs = itemData.specValueUs;
                  obj.specAttrKr = itemData.specValueKr;
                  obj.specAttrJp = itemData.specValueJp;
                  obj.isVisible = isVisible;
                  obj.basePrice = basePrice;
                  obj.baseStock = baseStock;

                  obj.aDiscount = parseInt((aDiscount / 10) * 100) / 100;
                  obj.bDiscount = parseInt((bDiscount / 10) * 100) / 100;
                  obj.cDiscount = parseInt((cDiscount / 10) * 100) / 100;
                  obj.dDiscount = parseInt((dDiscount / 10) * 100) / 100;
                  obj.skuList = skuLists;
                  obj.id = id;
                  obj.operator = localStorage.getItem("userName");

                  arrList.push(obj);
                });

                showLoading();
                const opt = {
                  url:
                    this.$route.query.type == "create"
                      ? reqUrl.worldCreateAddSightseeing
                      : reqUrl.worldEditSightseeing,
                  method: "POST",
                  params: {
                    id: this.dataRes.id,
                    cityId: this.ruleForm.cityId,
                    goodsName: this.ruleForm.goodsName,
                    goodsLocationName: this.ruleForm.goodsLocationName,
                    goodsLocationLng: this.locData.longitude, // 经度
                    goodsLocationLat: this.locData.latitude, // 纬度
                    images: this.zlist.join(","), // 图片
                    videos: this.fileListVideo.join(","), // 视频
                    cancelRule:
                      this.ruleForm2.policy == 0
                        ? this.ruleForm2.policy
                        : this.ruleForm2.policyOk, // 退订政策
                    isVisible: this.ruleForm.isVisible,
                    detailLink: localStorage.getItem("htmlUrlPlayZh"),
                    detailLinkEdit: editor.txt.html(),
                    specInfoAdminList: arrList,
                    specList: arrs,
                  },

                  resFunc: (res) => {
                    console.log(res.data);
                    if (res.data.code != 0) {
                      this.$notify.error({
                        title: "错误",
                        message: res.data.message,
                      });
                      hideLoading();
                    } else if (res.data.code == 0) {
                      this.$message({
                        type: "success",
                        message: "提交成功!",
                      });
                      hideLoading();
                      this.$router.push("/worldPlayList");
                    }
                    hideLoading();
                  },
                  errFunc: (err) => {
                    this.$notify.error({
                      title: "错误",
                      message: err,
                    });
                    hideLoading();
                    console.log(err, "err1");
                  },
                };
                request(opt);
              })
              .catch((err) => {
                console.log(err, "err");
                this.$message({
                  type: "info",
                  message: "已取消",
                });
              });
          } else {
            this.$message.error("请检查经纬度是否填写正确");
          }
        } else {
          this.$message.error("请检查信息是否填写正确");
        }
      });
    },

    divide(num1, num2) {
      // 检查除数是否为0，避免除以0的错误
      if (num2 === 0) {
        throw new Error("Cannot divide by zero");
      }
      // 返回两数相除的结果
      return num1 / num2;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../index.scss";
@import "../../mall/Goods/index.scss";

.submit {
  display: flex;
  justify-content: center;

  ::v-deep .el-button {
    width: 200px;
    border-radius: 20px;
  }
}
</style>
